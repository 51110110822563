@keyframes blinkGreen {
    0% {
        background-color: rgba(46, 125, 50, 0.5)
    }

    100% {
        background-color: rgba(46, 125, 50, 1)
    }
}

@keyframes blinkYellow {
    0% {
        background-color: rgba(130, 119, 23, 0.5)
    }

    100% {
        background-color: rgba(130, 119, 23, 1)
    }
}

@keyframes blinkBrown {
    0% {
        background-color: rgba(93, 64, 55, 0.5)
    }

    100% {
        background-color: rgba(93, 64, 55, 1)
    }
}

@keyframes blinkRed {
    0% {
        background-color: rgba(176, 0, 32, 0.5)
    }

    100% {
        background-color: rgba(176, 0, 32, 1)
    }
}